import { render, staticRenderFns } from "./home-index.vue?vue&type=template&id=d0a7c104&scoped=true&"
import script from "./home-index.vue?vue&type=script&lang=js&"
export * from "./home-index.vue?vue&type=script&lang=js&"
import style0 from "./home-index.vue?vue&type=style&index=0&id=d0a7c104&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0a7c104",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KContainer from '@web/components/core/k-container'
import KLayout from '@web/components/core/k-layout'
import KLink from '@web/components/core/k-link'
installComponents(component, {KButton,KContainer,KLayout,KLink})
