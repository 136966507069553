<template>
  <div class="tw-relative">
    <p>
      <k-link :to="{ name: 'event-detail', params: { id: event.id } }" class="tw-heading-5 hover:tw-underline">
        {{ event.name }}
      </k-link>
    </p>

    <p class="tw-text-gray-500 tw-text-sm tw-mt-2">
      <span class="tw-uppercase">
        {{
          formatDateRange(event.startDate, event.endDate)
        }}
        {{
          showLocation && event.city ? `, ${ event.city }` : ''
        }}
      </span>
    </p>

    <ul class="tw-list-none tw-p-0 tw-space-x-2 tw-flex tw-mt-4">
      <li v-for="topic in event.topics" :key="topic">
        <k-technology-chip :technology="topic" />
      </li>
    </ul>
  </div>
</template>

<script>
import { formatDateRange } from './../../utils/date'

export default {
  props: {
    event: {
      type: Object
    },
    showLocation: {
      type: Boolean
    }
  },
  computed: {},
  methods: {
    formatDateRange
  }
}
</script>
