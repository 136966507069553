<template>
  <k-layout :navProps="{ hasBorder: false }">
    <section class="tw-pt-8">
      <k-container>
        <template #default>
          <div class="tw-relative tw-overflow-hidden tw-bg-gray-700 tw-py-6 sm:tw-py-14 tw-text-gray-50 tw-rounded-2xl">
            <div class="tw-absolute tw-inset-0 tw-object-cover tw-h-full tw-w-full tw-bg-koderia" />

            <img
              src="../../assets/images/A4-Paper.jpg"
              alt="a4-paper-in-hands"
              aria-hidden="true"
              class="cv-image tw-object-cover tw-z-10 tw-px-6 tw-top-17 tw--right-100 tw-absolute sm:tw-px-0 sm:tw-mt-0 md:tw-hidden lg:tw-block lg:tw-right-1/6 md:tw-top-0"
            />

            <div
              class="tw-flex tw-flex-col tw-items-start tw-justify-between tw-relative tw-z-10 tw-px-6 tw-text-center sm:tw-text-left sm:tw-px-10"
            >
              <h1
                class="tw-text-2xl tw-leading-tight tw-text-left tw-font-bold tw-mb-42 md:tw-mb-4 md:tw-text-3.5xl"
                v-html="$t('homepage.title')"
              />

              <p class="tw-text-left md:tw-max-w-7/10 tw-text-lg tw-leading-7" v-html="$t('homepage.description')" />

              <k-link :to="cvTo" class="tw-mt-6 tw-self-stretch sm:tw-self-auto md:tw-mt-8">
                <k-button color="primary-inverted" class="tw-w-full sm:tw-w-auto">
                  {{ $t('homepage.actions.learn_more') }}
                </k-button>
              </k-link>
            </div>
          </div>
        </template>
      </k-container>
    </section>

    <project-list show-aside :sticky-top="45">
      <div class="tw-relative">
        <h2 class="tw-text-gray-800 tw-leading-tight tw-text-xl tw-font-bold tw-pt-4 tw-px-5 tw-pb-3 tw-border-b tw-border-gray-100">
          🌍 {{ $t('homepage.subtitles.newsfeed') }}
        </h2>
        <div class="tw-px-6 tw-pb-6">
          <ul class="tw-list-none tw-p-0 tw-divide-y tw-divide-gray-100">
            <li v-for="(newsFeedPost, idx) in newsFeedPosts" :key="newsFeedPost.id" class="tw-py-4">
              <div class="tw-relative tw-text-sm">
                <div v-if="idx === 0" class="tw-aspect-ratio-16:9 tw-rounded-xl tw-overflow-hidden tw-mb-3">
                  <img
                    class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover"
                    :src="newsFeedPost.image"
                    alt=""
                  />
                </div>

                <p class="tw-px-3">
                  <a
                    :href="newsFeedPost.link"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="tw-text-lg tw-font-bold tw-leading-tight tw-inline-block tw-no-underline tw-text-inherit"
                  >
                    <span aria-hidden="true" class="tw-absolute tw-inset-0"> <!-- Expand Tap Target --> </span>
                    {{ newsFeedPost.title }}
                  </a>
                </p>

                <div class="tw-px-3 tw-flex tw-items-center tw-justify-between tw-mt-2">
                  <div class="tw-flex tw-min-w-0 tw-items-center">
                    <img
                      :src="newsFeedPost.icon"
                      @error="$event.target.src = '/web/home/default_favicon.png'"
                      class="tw-rounded-full tw-w-5 tw-h-5 tw-mr-1"
                      alt=""
                    />

                    <p class="tw-text-gray-500 tw-text-sm tw-leading-relaxed tw-truncate">
                      {{ newsFeedPost.siteName }}
                    </p>
                  </div>
                  <p class="tw-text-gray-500 tw-text-sm tw-leading-relaxed tw-flex-shrink-0 tw-ml-2">
                    {{ newsFeedPost.timestamp | formatDateRelativeSimple }}
                  </p>
                </div>
              </div>
            </li>
          </ul>

          <k-link :to="{ name: 'newsfeed-index' }" class="tw-w-full tw-mt-2">
            <k-button color="regular" class="tw-w-full">
              {{ $t('homepage.actions.newsfeed') }}
            </k-button>
          </k-link>
        </div>
      </div>
    </project-list>

    <section class="tw-bg-white tw-py-24">
      <k-container>
        <template #default>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-end">
            <h2 class="tw-heading-3">{{ $t('homepage.subtitles.events') }} 📆</h2>
            <p class="tw-mt-6 tw-flex-shrink-0 lg:tw-mt-0 tw-text-base">
              <k-link :to="{ name: 'event-index' }" class="tw-text-gray-500 hover:tw-text-gray-700">
                {{ $t('homepage.actions.events') }} ›
              </k-link>
            </p>
          </div>

          <div class="tw-grid md:tw-grid-cols-2 tw-mt-16 tw-gap-x-16 tw-gap-y-12 tw-grid-cols-1">
            <div>
              <p class="tw-pb-6 tw-heading-4 tw-border-b-2 tw-border-gray-100">{{ $t('event.subtitles.online') }}</p>
              <div class="tw-mt-4">
                <ul class="tw-list-none tw-p-0 tw-divide-y tw-divide-gray-100">
                  <li v-for="event in upcomingOnlineEvents" :key="event.id" class="tw-py-6">
                    <event-preview :event="event" />
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p class="tw-pb-6 tw-heading-4 tw-border-b-2 tw-border-gray-100">
                {{ $t('event.subtitles.slovakia_czech') }}
              </p>
              <div class="tw-mt-4">
                <ul class="tw-list-none tw-p-0 tw-divide-y tw-divide-gray-100">
                  <li v-for="event in upcomingLocalEvents" :key="event.id" class="tw-py-6">
                    <event-preview :event="event" show-location />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </k-container>
    </section>

    <section class="tw-bg-gray-700 tw-text-gray-50 tw-py-28">
      <k-container>
        <template #default>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-end">
            <h2 class="tw-heading-3 tw-text-gray-50">{{ $t('homepage.subtitles.blogs') }} ✍️</h2>

            <p class="tw-mt-6 tw-flex-shrink-0 lg:tw-mt-0 tw-text-base">
              <k-link :to="{ name: 'blog-index' }" class="tw-text-gray-200 hover:tw-text-white">
                {{ $t('homepage.actions.blogs') }} ›
              </k-link>
            </p>
          </div>

          <ul class="tw-list-none tw-grid tw-gap-6 tw-p-0 tw-m-0 tw-mt-10 tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-text-gray-700">
            <li
              v-for="blogPost in blogPosts"
              :key="blogPost.id"
              class="tw-transform tw-transition-transform hover:tw-scale-105 tw-duration-200 tw-ease-in-out"
            >
              <div class="tw-flex tw-flex-col tw-bg-gray-700 tw-justify-start tw-relative tw-h-full tw-rounded-sm tw-overflow-hidden">
                <div class="tw-aspect-ratio-16:9">
                  <img
                    class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover"
                    :src="blogPost.featuredImage"
                    alt=""
                  />
                </div>

                <div class="tw-px-4 tw-py-6 tw-h-full tw-bg-white">
                  <p>
                    <k-link
                      :to="{
                        name: 'blog-detail',
                        params: {
                          slug: blogPost.slug
                        }
                      }"
                      class="tw-heading-4 tw-no-underline tw-text-gray-700"
                    >
                      <span aria-hidden="true" class="tw-absolute tw-inset-0"> <!-- Expand Tap Target --> </span>
                      {{ postGetAttr(blogPost, 'title', locale) }}
                    </k-link>
                  </p>

                  <p class="tw-mt-2 tw-text-base">{{ blogPost.author.name }}</p>
                </div>
              </div>
            </li>
          </ul>
        </template>
      </k-container>
    </section>
  </k-layout>
</template>

<script>
import EventPreview from './../../events/components/event-preview'

import { formatDateRange } from './../../utils/date'
import ProjectList from '@web/projects/components/project-list'
import KButton from '@web/components/core/k-button'
import { postGetAttr } from '@web/blog/utils'
import { getRouteLocale } from '@web/constants/language'

export default {
  components: {
    KButton,
    ProjectList,
    EventPreview
  },
  created() {
    this.$store.dispatch('BLOG/fetchInitialPosts')
    this.$store.dispatch('POSTS/initiateGetPosts')
  },

  computed: {
    locale() {
      return getRouteLocale()
    },
    newsFeedPosts() {
      const items = this.$store.getters['NEWSFEED/newsfeed']
      return items.slice(0, 5)
    },

    upcomingOnlineEvents() {
      const items = this.$store.getters['EVENTS/upcomingOnlineEvents']
      return items.slice(0, 5)
    },

    upcomingLocalEvents() {
      const items = this.$store.getters['EVENTS/upcomingLocalEvents']
      return items.slice(0, 5)
    },

    blogPosts() {
      const items = this.$store.getters['BLOG/allPosts']
      return items.slice(0, 4)
    },
    hasCV() {
      return this.$store.state.USER.hasUserCv
    },
    cvTo() {
      return this.hasCV ? { name: 'profile-cv' } : { name: 'cv-landing-page' }
    }
  },

  methods: {
    formatDateRange,
    postGetAttr
  }
}
</script>

<style lang="scss" scoped>
.cv-image {
  width: 300px;

  @include lg {
    width: 370px;
  }
}
</style>
